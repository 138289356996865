<template>
    <div class="main__header">
        <p>パスワード忘れた場合</p>
    </div>
    <div class="content">
        <div class="content__container wrapper --mid">
            <div class="form setting">
                <div class="form__title">登録したメールアドレスを入力してください</div>
                <div class="form__field is-padding_b25">
                    <input type="text" v-model="toAddress" class="is-margin_b0" style="width: 550px;">
                </div>
                <div class="form__btns is-justify-center is-padding_t50">
                    <p class="c-blue t-bold is-padding_l20">
                        <button class="btn btn-orange-md" :disabled="!isFormValid" v-on:click="sendMail()" :style="{'cursor': isFormValid ? 'pointer' : 'not-allowed'}">
                                                メールを送信
                                                </button>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //import axios from 'axios';
    import authMethodsMixin from './mixins/authMethods.vue';
    import messages from '@/constants/message';
    export default {
        mixins: [authMethodsMixin],
        data() {
            return {
                toAddress: "",
                err: [],
                error: [],
                message: [],
            };
        },
        computed: {
            isFormValid() {
                return this.toAddress.trim() !== '';
            }
        },
        methods: {
            async sendMail() {
                const url = "/reset/sendMail?toAddress=" + this.toAddress;
                await this.doPostWithAuth(url, this.goLogin(), messages.E_SC102_06, null, {
                toAddress: this.toAddress
                });
            },
            goLogin() {
                this.$router.push('/login');
            }
        }
    }
</script>