<template>
    <div class="main__header">
        <p>パスワード再設定</p>
    </div>
    <div class="content">
        <div class="content__container wrapper --mid">
            <div class="form setting">
                <div class="form__title">新しいパスワード</div>
                <div class="form__field is-padding_b25">
                    <input type="password" class="is-margin_b0" v-model="newPassword" @input="onInput" placeholder="半角の英字と数字を含む、文字数は8〜12文字である必要があります" style="width: 550px;" maxlength="12">
                </div>
                <div class="form__title">新しいパスワード（確認）</div>
                <div class="form__field is-padding_b25">
                    <input type="password" class="is-margin_b0" v-model="confirmPassword" @input="onInput" maxlength="12" placeholder="半角の英字と数字を含む、文字数は8〜12文字である必要があります" style="width: 550px;">
                    <p v-if="newPassword !== confirmPassword && confirmPassword.length > 0" class="t-bold is-padding_l20 error-message" style="color: red;">* 新しいパスワードと確認用パスワードが一致しません。</p>
                </div>
                <div class="form__btns is-justify-center is-padding_t50">
                    <p class="c-blue t-bold is-padding_l20">
                        <button class="btn btn-orange-md" :disabled="!isFormValid" v-on:click="updatePassword()" :style="{'cursor': isFormValid ? 'pointer' : 'not-allowed'}">
                                                                    設定
                                                    </button>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import authMethodsMixin from './mixins/authMethods.vue';
    import messages from '@/constants/message';
    export default {
        mixins: [authMethodsMixin],
        data() {
            return {
                newPassword: "",
                confirmPassword: "",
                err: [],
                error: [],
                message: [],
            };
        },
        mounted() {
            this.userId = this.$route.query.userId;
            this.initPassword = this.$route.query.initPassword;
        },
        computed: {
            isFormValid() {
                return this.newPassword !== '' &&
                    this.confirmPassword !== '' &&
                    this.newPassword === this.confirmPassword &&
                    this.newPassword.length >= 8 &&
                    this.confirmPassword.length >= 8;
            }
        },
        methods: {
            async updatePassword() {
                const url = "/reset/updatePassByInitPassword?newPassword=" +
                    this.newPassword + "&userId=" + this.userId + "&initPassword=" + this.initPassword;
                try {
                    const response = await this.doPostWithAuth(url,
                        this.goLogin(), messages.E_SC102_04, {
                        newPassword: this.newPassword
                    }, {
                        userId: this.userId
                    }, {
                        initPassword: this.initPassword
                    });
                    console.log(response.data);
                } catch (error) {
                    console.error(error);
                }
            },
            onInput(event) {
                // 英語の文字と数字のみが使用できます
                const regex = /^[0-9a-zA-Z]*$/
                const newValue = event.target.value
                if (regex.test(newValue)) {
                    this.inputValue = newValue
                } else {
                    // 入力値には特殊文字が含まれています。入力値を更新しないでください。
                    event.target.value = this.inputValue
                }
            },
            goLogin() {
                this.$router.push('/login');
            }
        }
    }
</script>