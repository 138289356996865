<template>
  <aside id="sidebar" class="sidebar">
    <!-- SIDEBAR CONTENT -->
    <div class="sidebar__content">
      <ul class="sidebar__menu js-side">
        <li v-bind:class="{ selected: routeName === 'ExchangeManagement' }">
          <router-link to="/exchangeManagement">
            <img src="@/assets/images/ico-tk-list.png" alt="" />
            <p>取引所一覧</p>
          </router-link>
        </li>
        <li v-bind:class="{ selected: routeName === 'CurrencyList' }">
          <router-link to="/currencylist">
            <img src="@/assets/images/ico-pair-list.png" alt="" />
            <p>通貨ペア一覧</p>
          </router-link>
        </li>
        <li v-bind:class="{ selected: routeName === 'ConditionManagement' }">
          <router-link to="/conditionManagement">
            <img src="@/assets/images/ico-list.png" alt="" />
            <p>取引条件一覧</p>
          </router-link>
        </li>
        <li v-bind:class="{ selected: routeName === 'OrderManagement' }">
          <router-link to="/orderManagement">
            <img src="@/assets/images/ico-order.png" alt="" />
            <p>注文履歴</p>
          </router-link>
        </li>
        <li v-bind:class="{ selected: routeName === 'TradeManagement' }">
          <router-link to="/tradeManagement">
            <img src="@/assets/images/ico-tk-rireki.png" alt="" />
            <p>取引履歴</p>
          </router-link>
        </li>
        <li v-bind:class="{ selected: routeName === 'AssetsInfo' }">
          <router-link to="/assets">
            <img src="@/assets/images/ico-asset.png" alt="" />
            <p>資産情報</p>
          </router-link>
        </li>
        <li v-bind:class="{ selected: routeName === 'TradeVolume' }">
          <router-link to="/tradeVolume">
            <img src="@/assets/images/ico-cash.png" alt="" />
            <p>出来高情報</p>
          </router-link>
        </li>
        <li v-bind:class="{ selected: routeName === 'WalletManagement' }">
          <router-link to="/walletManagement">
            <img src="@/assets/images/ico-wallet.png" alt="" />
            <p>ウォレット管理</p>
          </router-link>
        </li>
        <li v-bind:class="{ selected: routeName === 'WalletAccountList' }">
          <router-link to="/walletAccountList">
            <img src="@/assets/images/ico-man.png" alt="" />
            <p>ウォレットアカウント一覧</p>
          </router-link>
        </li>
        <li v-bind:class="{ selected: routeName === 'Send' }">
          <router-link to="/send">
            <img src="@/assets/images/ico-cash.png" alt="" />
            <p>送金</p>
          </router-link>
        </li>
        <li v-bind:class="{ selected: routeName === 'TransactionList' }">
          <router-link to="/transactionList">
            <img src="@/assets/images/ico-briefcase.png" alt="" />
            <p>トランザクション一覧</p>
          </router-link>
        </li>
        <li v-bind:class="{ selected: routeName === 'StandbyTransactionList' }">
          <router-link to="/standbyTransactionList">
            <img src="@/assets/images/ico-briefcase-rev.png" alt="" />
            <p>予約トランザクション一覧</p>
          </router-link>
        </li>
        <li v-bind:class="{ selected: routeName === 'UserDetail' }">
          <router-link to="/UserDetail">
            <img src="@/assets/images/ico-pwss-update.png" alt="" />
            <p>アカウント管理</p>
          </router-link>
        </li>
        <li>
          <router-link to="/logout">
            <img src="@/assets/images/ico-logout.png" alt="" />
            <p>ログアウト</p>
          </router-link>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  computed: {
    routeName() {
      return this.$route.matched[0].name;
    },
  },
};
</script>
