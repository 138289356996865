import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL;

/**
 * 各画面の認証を実施する
 *
 */
async function validateAuth() {
  const jwt = localStorage.getItem("jwt") ?? "";
  const validate = await axios
    .get(baseUrl + "/login/validateAuth", {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        return true;
      } else {
        // レスポンスが200でない場合、'jwt'を削除しログイン画面に遷移する
        localStorage.removeItem("jwt");
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      localStorage.removeItem("jwt");
      return false;
    });

  return validate;
}

export default validateAuth;
