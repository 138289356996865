import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router/index";
import "@/assets/css/style.css";
import "@/assets/css/font-awesome.min.css";
import { Buffer } from "buffer";
import axios from "axios";

// CORSの制限にてブラウザのCookiesにJSESSIONIDが保存されない問題対応
axios.defaults.withCredentials = true;

// ReferenceError: Buffer is not defined のエラー防止対応 
window.Buffer = Buffer;

const app = createApp(App);

app.use(router).mount("#app");