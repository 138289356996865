<template>
  <div class="container">
    <!-- sidebar -->
    <Sidebar />

    <!-- main -->
    <main class="main">
      <!-- header -->
      <Header />

      <div class="main__content">
        <div>
          <router-view />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Header from "@/layout/components/Header";
import Sidebar from "@/layout/components/Sidebar";

export default {
  name: "Layout",
  components: {
    Header,
    Sidebar,
  },
};
</script>
