<template>
  <div class="header">
    <div class="header__title">
      <img :src="icon" alt="" />
      <p>{{ title }}</p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";

const title = ref();
const icon = ref();

export default {
  setup() {
    const router = useRoute();

    if (router.meta.headerIcon) {
      icon.value = router.meta.headerIcon;
    }
    if (router.meta.headerTitle) {
      title.value = router.meta.headerTitle;
    }

    return {
      icon,
      title,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      icon.value = this.$route.meta.headerIcon;
      title.value = this.$route.meta.headerTitle;
    },
  },
};
</script>
