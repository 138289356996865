<script>
import axios from "axios";
import messages from '@/constants/message';

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    validateAuth() {
      this.doGetWithAuth("/login/validateAuth");
    },
    doGetWithAuth(url, successCallback, alertMessage, failureCallback) {
      this.doWithAuth('get', url, successCallback, alertMessage, null, failureCallback);
    },
    doGetWithAuthAndData(url, successCallback, alertMessage, data, failureCallback) {
      if (data) {
      url += '?' + Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&');
      }
    this.doWithAuth('get', url, successCallback, alertMessage, null, failureCallback);
    },
    doPostWithAuth(url, successCallback, alertMessage, data, failureCallback) {
      this.doWithAuth('post', url, successCallback, alertMessage, data, failureCallback);
    },
    doWithAuth(method, url, successCallback, alertMessage, data, failureCallback) {
      const jwt = localStorage.getItem('jwt') ?? '';
      const headers = {
        'Authorization': `Bearer ${jwt}`
      };
      return axios({
        method: method,
        url: this.baseUrl + url,
        data: data,
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200  || response.status === 201  || response.status === 204) {
          if (successCallback) {
            successCallback(response);
          }
        } else if (response.status === 401  || response.status === 203) {
          this.clearAndGoLogin();
        } else if (response.status === 404) {
          alert(messages.E_SC000_03)
        } else {
          this.alertOrGoLogin(alertMessage);
        }
      })
      .catch((error) => {
        if (failureCallback) {
          failureCallback(error);
        } else {
          this.alertOrGoLogin(alertMessage);
        }
      });
    },
    alertOrGoLogin(alertMessage) {
      if (alertMessage) {
        alert(alertMessage);
      } else {
        this.clearAndGoLogin();
      }
    },
    clearAndGoLogin() {
      localStorage.clear();
      this.clearCookie();
      this.$router.push('/login');
    },
    clearCookie() {
      var keys = document.cookie.match(/[^ =;]+(?=)/g);
      if (keys) {
        let expStr = new Date(0).toUTCString();
        for (var i = keys.length; i--;) {
          document.cookie = keys[i] + '=0;expires=' + expStr;
        }
      }
    }
  }
}
</script>