import { createRouter, createWebHistory } from "vue-router";
import NotFound from "@/components/NotFound.vue";
import Login from "@/components/autobuysell/Login.vue";
import Layout from "@/layout/index.vue";
import validateAuth from "@/utils/authentication";
import SendResetPasswordMail from "@/components/autobuysell/SendResetPasswordMail.vue";
import ResetPassword from "@/components/autobuysell/ResetPassword.vue";
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      headerTitle: "",
    },
    component: () => import("@/components/autobuysell/Home.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      headerTitle: "ログイン",
    },
  },
  {
    path: "/logout",
    name: "Logout",
    meta: {
      headerTitle: "ログアウト",
    },
    component: () => import("@/components/autobuysell/Logout.vue"),
  },
  {
    path: "/changeTradePassword",
    name: "ChangeTradePassword",
    component: Layout,
    meta: {
      headerTitle: "アカウント管理",
      headerIcon: require("@/assets/images/ico-pwss-update-blue.png"),
    },
    children: [
      {
        path: "",
        component: () => import("@/components/autobuysell/ChangeTradePassword.vue"),
      },
    ],
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    component: Layout,
    meta: {
      headerTitle: "アカウント管理",
      headerIcon: require("@/assets/images/ico-pwss-update-blue.png"),
    },
    children: [
      {
        path: "",
        component: () => import("@/components/autobuysell/ChangePassword.vue"),
      },
    ],
  },
  {
    path: "/sendResetPasswordMail",
    name: "SendResetPasswordMail",
    component: SendResetPasswordMail,
    meta:{
      headerTitle:"パスワード忘れた場合",
    }
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      requiredAuth: false,
      headerTitle:"パスワード忘れた場合",
    }
  },
  {
    path: "/userdetail",
    name: "UserDetail",
    component: Layout,
    meta: {
      headerTitle: "アカウント管理",
      headerIcon: require("@/assets/images/ico-pwss-update-blue.png"),
    },
    children: [
      {
        path: "",
        component: () => import("@/components/autobuysell/UserDetail.vue"),
      },
    ],   
  },
  {
    path: "/userdetailedit",
    name: "UserDetailEdit",
    component: Layout,
    meta: {
      headerTitle: "アカウント管理",
      headerIcon: require("@/assets/images/ico-pwss-update-blue.png"),
    },
    children: [
      {
        path: "",
        component: () => import("@/components/autobuysell/UserDetailEdit.vue"),
      },
    ],   
  },
  {
      path: "/exchangeManagement",
      name: "ExchangeManagement",
      component: Layout,
      meta: {
        headerTitle: "取引所",
        headerIcon: require("@/assets/images/ico-tk-list-blue.png"),
      },
      children: [
        {
          path: "",
          component: () => import("@/components/autobuysell/ExchangeList.vue"),
        },
        {
          path: "exchangeDetail",
          component: () => import("@/components/autobuysell/ExchangeDetail.vue"),
        },
      ],
    },
  {
    path: "/conditionManagement",
    name: "ConditionManagement",
    component: Layout,
    meta: {
      headerTitle: "取引条件",
      headerIcon: require("@/assets/images/ico-list-blue.png"),
    },
    children: [
      {
        path: "",
        component: () => import("@/components/autobuysell/TradeConditionList.vue"),
      },
      {
        path: "tradeConditionRegist",
        component: () => import("@/components/autobuysell/TradeConditionRegist.vue"),
      },
      {
        path: "tradeConditionEdit",
        component: () => import("@/components/autobuysell/TradeConditionEdit.vue"),
      },
    ],   
  },
  {
    path: "/currencyregister",
    name: "CurrencyRegister",
    component: Layout,
    meta: {
      headerTitle: "通貨ペア情報",
      headerIcon: require("@/assets/images/ico-pair-list-blue.png"),
    },
    children: [
      {
        path: "",
        component: () => import("@/components/autobuysell/CurrencyRegister.vue"),
      },
    ],   
  },
  {
    path: "/tradeManagement",
    name: "TradeManagement",
    component: Layout,
    meta: {
      headerTitle: "取引履歴",
      headerIcon: require("@/assets/images/ico-tk-rireki-blue.png"),
    },
    children: [
      {
        path: "",
        component: () => import("@/components/autobuysell/TradeHistoryList.vue"),
      },
    ],   
  },
  {
    path: "/orderManagement",
    name: "OrderManagement",
    component: Layout,
    meta: {
      headerTitle: "注文履歴",
      headerIcon: require("@/assets/images/ico-order-blue.png"),
    },
    children: [
      {
        path: "",
        component: () => import("@/components/autobuysell/OrderHistoryList.vue"),
      },
      {
        path: "orderDetail",
        component: () => import("@/components/autobuysell/OrderDetail.vue"),
      },
    ],   
  },
  {
    path: "/assets",
    name: "AssetsInfo",
    component: Layout,
    meta: {
      headerTitle: "資産情報",
      headerIcon: require("@/assets/images/ico-asset-blue.png"),
    },
    children: [
      {
        path: "",
        component: () => import("@/components/autobuysell/AssetsInfo.vue"),
      },
    ],   
  },
  {
    path: "/currencylist",
    name: "CurrencyList",
    component: Layout,
    meta: {
      headerTitle: "通貨ペア情報",
      headerIcon: require("@/assets/images/ico-pair-list-blue.png"),
    },
    children: [
      {
        path: "",
        component: () => import("@/components/autobuysell/CurrencyList.vue"),
      },
    ],   
  },
  {
    path: "/tradeVolume",
    name: "TradeVolume",
    component: Layout,
    meta: {
      headerTitle: "出来高情報",
      headerIcon: require("@/assets/images/ico-cash-blue.png"),
    },
    children: [
      {
        path: "",
        component: () => import("@/components/autobuysell/TradeVolume.vue"),
      },
    ],   
  },
  {
    path: "/walletManagement",
    name: "WalletManagement",
    component: Layout,
    meta: {
      headerTitle: "ウォレット管理",
      headerIcon: require("@/assets/images/ico-wallet-blue.png"),
      requiredAuth: true,
    },
    children: [
      {
        path: "",
        name: "WalletManagement",
        component: () => import("@/components/wallet/wallet-management/WalletManagement.vue"),
      },
      {
        path: "createWallet",
        name: "CreateWallet",
        component: () => import("@/components/wallet/wallet-management/CreateWallet.vue"),
      },
      {
        path: "walletImport",
        name: "WalletImport",
        component: () => import("@/components/wallet/wallet-management/WalletImport.vue"),
      },
      {
        path: "createAccount",
        name: "CreateAccount",
        component: () => import("@/components/wallet/account-management/CreateAccount.vue"),
      },
      {
        path: "accountImport",
        name: "AccountImport",
        component: () => import("@/components/wallet/account-management/AccountImport.vue"),
      },
      {
        path: "tagManagement",
        name: "TagManagement",
        component: () => import("@/components/wallet/account-management/TagManagement.vue"),
      },
      {
        path: "editTag/:id",
        name: "EditTag",
        component: () => import("@/components/wallet/account-management/EditTag.vue"),
        props: true
      },
      {
        path: "createTag/",
        name: "CreateTag",
        component: () => import("@/components/wallet/account-management/CreateTag.vue"),
        props: true
      },
      {
        path: "showRecoveryPhrase",
        name: "ShowRecoveryPhrase",
        component: () => import("@/components/wallet/account-management/ShowRecoveryPhrase.vue"),
      },
      {
        path: "addressBookManagement",
        name: "AddressBookManagement",
        component: () => import("@/components/wallet/account-management/AddressBookManagement.vue"),
      },
      {
        path: "editAddress/:networkType/:index",
        name: "EditAddress",
        component: () => import("@/components/wallet/account-management/EditAddressBookInfo.vue"),
        props: true
      },
      {
        path: "createAddress/:networkType",
        name: "CreateAddress",
        component: () => import("@/components/wallet/account-management/CreateAddressBookInfo.vue"),
        props: true
      },
      {
        path: "walletReset",
        name: "WalletReset",
        component: () => import("@/components/wallet/account-management/WalletReset.vue"),
      },
    ],
  },
  {
    path: "/walletAccountList",
    name: "WalletAccountList",
    component: Layout,
    meta: {
      headerTitle: "ウォレットアカウント一覧",
      headerIcon: require("@/assets/images/ico-wallet-blue.png"),
      requiredAuth: true,
    },
    children: [
      {
        path: "",
        name: "WalletAccountList",
        component: () => import("@/components/wallet/account-list/WalletAccountList.vue"),
      },
      {
        path: "walletAccountDetail/:id",
        name: "WalletAccountDetail",
        component: () => import("@/components/wallet/account-list/WalletAccountDetail.vue"),
        props: true,
        meta: {
          headerTitle: "ウォレットアカウント詳細",
        },
      },
    ],
  },
  {
    path: "/transactionList",
    name: "TransactionList",
    component: Layout,
    meta: {
      headerTitle: "トランザクション一覧",
      headerIcon: require("@/assets/images/ico-briefcase-blue.png"),
      requiredAuth: true,
    },
    children: [
      {
        path: "",
        component: () => import("@/components/wallet/transaction-list/TransactionList.vue"),
      },
    ],
  },
  {
    path: "/standbyTransactionList",
    name: "StandbyTransactionList",
    component: Layout,
    meta: {
      headerTitle: "予約トランザクション一覧",
      headerIcon: require("@/assets/images/ico-briefcase-rev-blue.png"),
      requiredAuth: true,
    },
    children: [
      {
        path: "",
        component: () => import("@/components/wallet/transaction-list/StandbyTransactionList.vue"),
      },
    ],
  },
  // {
  //   path: "/cryptoSample",
  //   name: "CryptoSample",
  //   component: Layout,
  //   meta: {
  //     headerTitle: "クリプトサンプル",
  //     // headerIcon: require("@/assets/images/ico-wallet-blue.png"),
  //   },
  //   children: [
  //     {
  //       path: "",
  //       name: "CryptoSample",
  //       component: () => import("@/components/wallet/CryptoSample.vue"),
  //     },
  //   ],
  // },
  {
    path: "/send",
    name: "Send",
    component: Layout,
    meta: {
      headerTitle: "送金",
      headerIcon: require("@/assets/images/ico-wallet-blue.png"),
      requiredAuth: true,
    },
    children: [
      {
        path: "",
        name: "Send",
        component: () => import("@/components/wallet/send-transaction/Send.vue"),
      },
      {
        path: "createSendTransaction",
        name: "CreateSendTransaction",
        meta: {
          headerTitle: "送金",
        },
        component: () => import("@/components/wallet/send-transaction/CreateSendTransaction.vue"),
        props: route => ({ cryptocurrencyId: route.query.cryptocurrency, fromAccountId: route.query.fromAccount })
      },
      {
        path: "createDistributionSendTransaction",
        name: "CreateDistributionSendTransaction",
        meta: {
          headerTitle: "振り分け送金",
        },
        component: () => import("@/components/wallet/send-transaction/CreateDistributionSendTransaction.vue"),
      },
      {
        path: "createSwingSendTransaction",
        name: "CreateSwingSendTransaction",
        meta: {
          headerTitle: "スィング送金",
        },
        component: () => import("@/components/wallet/send-transaction/CreateSwingSendTransaction.vue"),
      },
    ],
  },
  { path: "/:notFound(.*)", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

/**
 * 認証チェックを行う
 * 認証が必要な画面に関しては、metaに
 * requiredAuth: true
 * を付与してください
 */
router.beforeEach(async (to, _from, next) => {
  // 認証
  const requiredAuth = to.matched.some((record) => record.meta.requiredAuth);
  if (requiredAuth == true && !(await validateAuth())) {
    next({ path: "/login" });
  } else {
    next();
  }
});

router.afterEach((to) => {
  document.title = to.meta.headerTitle
})

export default router;
