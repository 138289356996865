const messages = {
  E_SC000_01: 'ログアウトに失敗しました。システム管理者に連絡してください。',
  E_SC000_02: '取引パスワードは正しくありません。',
  E_SC000_03: 'データが変更されている可能性があります。再度処理をやり直して下さい。',
  E_SC000_04: '編集中のものは保存されませんが、よろしいですか？',
  E_SC001_01: '初期処理に失敗しました。システム管理者に連絡してください。',
  E_SC001_02: '{0}を入力してください。',
  E_SC001_03: 'ユーザー名またはパスワードが間違っています。',
  E_SC001_04: 'ログインに失敗しました。システム管理者に連絡してください。',
  E_SC004_01: '情報の取得に失敗しました。システム管理者に連絡してください。',
  E_SC004_02: '{0}を入力してください。',
  E_SC004_03: '{0}を正しいフォーマットで入力してください。',
  E_SC004_04: '登録に失敗しました。システム管理者に連絡してください。',
  E_SC004_05: '価格は0.000000001の整数倍である必要があります。',
  E_SC102_01: '英数10文字以上を設定してください。',
  E_SC102_02: 'パスワードが一致しません。',
  E_SC102_03: 'シークレットリカバリーフレーズが正しくバックアップされていません。',
  E_SC102_04: '更新に失敗しました。システム管理者に連絡してください。',
  E_SC102_05: 'シークレットリカバリーフレーズが正しくありません。',
  E_SC102_06: '送信に失敗しました。システム管理者に連絡してください。',
  E_SC104_01: 'システム内で登録済みのアカウントです。アカウントの同期処理を行います。',
  E_SC104_02: 'アカウントを作成が完了しました。',
  E_SC104_03: 'パスワードが正しくありません。',
  E_SC104_04: 'システムエラー。システム管理者に連絡してください。',
  E_SC105_01: 'システム内に登録済みのウォレットがあります。同一ウォレットのアカウント同期処理を行います。',
  E_SC105_02: 'システム内で登録済みのアカウントです。アカウント名などの編集はアカウント一覧画面から可能です。',
  E_SC105_03: 'アカウントのインポートが完了しました。',
  E_SC105_04: 'Ledgerが接続されていないか、ロックされています。Ledgerが接続されているか確認し、ロックを解除してください。',
  E_SC106_01: '情報の取得に失敗しました。システム管理者に連絡してください。',
  E_SC106_02: '登録に失敗しました。システム管理者に連絡してください。',
  E_SC106_03: '更新に失敗しました。システム管理者に連絡してください。',
  E_SC106_04: '削除に失敗しました。システム管理者に連絡してください。',
  E_SC108_01: '情報の取得に失敗しました。システム管理者に連絡してください。',
  E_SC109_01: '更新に失敗しました。システム管理者に連絡してください。',
  E_SC110_01: '情報の取得に失敗しました。システム管理者に連絡してください。',
  E_SC110_02: '確認メールを送信しました。メールをご確認ください。',
  E_SC110_03: '確認に失敗しました。システム管理者に連絡してください。',
  E_SC110_04: '送金に失敗しました。システム管理者に連絡してください。',
  E_SC110_05: '他のユーザーにより同一アカウントアドレスからの送金処理が行われました。本送金をキャンセルし、もう一度送金設定をやり直してください。',
  E_SC110_06: '送金に成功しました。',
  E_SC110_07: '予約注文があるため、即時送金をすることができません。予約トランザクション一覧画面から予約注文をキャンセルするか、日時指定で送金してください。\n【最終予約日時】{0}',
  E_SC110_08: '送金元のアカウント情報が登録されていません。',
  E_SC110_09: '送金に失敗しました。',
  E_SC110_10: '認証コードに誤りがあります。受信したメールを確認し、認証コードに間違いが無いか確認してください。',
  E_SC110_11: '送金額を入力してください。',
  E_SC110_12: '送金する日時を入力してください。',
  E_SC110_13: 'アドレスの形式が間違っています。',
  E_SC110_14: '残高不足です。',
  E_SC110_15: '最終予約注文より前の送金日時が設定されています。予約トランザクション一覧画面から予約注文をキャンセルするか、送金日時を設定しなおしてください。\n【最終予約日時】{0}',
  E_SC110_16: '予約注文があるため、送金をすることができません。予約トランザクション一覧画面から予約注文をキャンセルするか、他のアカウントで送金してください。\n【最終予約日時】{0}',
  E_SC110_17: '認証コードは必須です。',
  E_SC110_18: 'パスワードは必須です。',
  E_SC110_19: '認証コードは数字のみで入力してください。',
  E_SC110_20: '手数料が高騰していますが、送金してよろしいでしょうか？',
  E_SC110_21: '基準ガス価格より低いガス価格が設定されています。ガス価格は基準ガス価格より高く設定してください。\n【基準ガス価格】{0}', 
  E_SC114_01: '情報の取得に失敗しました。システム管理者に連絡してください。',
  E_SC114_02: 'アカウントを選択してください。',
  E_SC114_03: 'ウォレットを作成してください。',
  E_SC115_01: 'キャンセルに失敗しました。システム管理者に連絡してください。',
  E_SC115_02: '他のユーザーによって更新されています。再度予約トランザクション一覧を検索し、キャンセルしてください。',
  E_SC116_01: 'ウォレットをリセットします。よろしいでしょうか。',
  E_SC116_02: '本当にリセットしてよろしいでしょうか。',
  E_SC116_03: 'リセットしました。',
  E_SC117_01: '入力されたアカウントアドレスは登録済みです。他のアカウントアドレスを入力してください。',
  E_SC015_01: '通貨ペア登録失敗しました。システム管理者に連絡してください。',
  E_SC015_04: '通貨を設定してください。',
  E_SC015_05: '通貨ペアが正常に登録されました。',
  E_SC015_06: '通貨名が重複しています。正しく入力してください。',
  E_SC015_07: '時間範囲は正しくありません。',
  E_SC018_08: 'アカウント情報が正常に更新されました。',
  E_SC018_09: 'アカウント情報の更新は失敗しました。',
  E_SC012_10: '(カスタム時間の選択範囲は{0}です)',
  E_SC015_11: '使用中の通貨の削除はできません。',
  E_SC004_12: '同一取引所・取引ルール・通貨の取引条件は既に設定されています。',
  E_SC004_13: '取引日時の終了日時は{0}より前の日時で登録できません。',
  E_SC004_14: '使用中の取引条件、または取引条件が変更しましたのため削除はできません。条件番号:{0}',
  E_SC017_15: '取引パスワードの変更は成功しました。',
  E_SC016_16: 'パスワードが正常に更新されました。',
  E_SC016_17: 'パスワードの更新は失敗しました。',
}

export default messages;
