<template>
  <div class="container">
      <main class="login">
        <div class="login__content">
          <div class="login__title">
            <p>ログイン画面</p>
          </div>
          <div class="login__form">
            <div class="form">
              <div class="form__title">ログインID</div>
              <div class="form__field">
                <input type="text" v-model="loginId">
              </div>
              <div class="form__title">パスワード</div>
              <div class="form__field">
                <input type="password" v-model="password">
              </div>
              <div class="form__btns is-justify-center">
                <button @click="handleLogin()" class="btn btn-orange-md">ログイン</button>
              </div>
              <div style="margin-bottom: 20px;"></div>
              <div class="form__message is-margin_b20">
              <p>
                <router-link to="/sendResetPasswordMail" class="link">パスワード忘れた場合</router-link>
              </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
</template>

<script>
import axios from "axios";
import messages from '@/constants/message';

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      loginId: "",
      password: "",
      error: [],
      message: [],
    };
  },
  mounted() {
    //認証
    const jwt = localStorage.getItem('jwt') ?? '';
    axios.get(this.baseUrl + "/login/validateAuth",
          {
              headers: {
                'Authorization': `Bearer ${jwt}`
              },
          }
         )
     .then(response => {
         if (response.status === 200) {
             // レスポンスが200の場合、注文履歴画面に遷移する
             this.$router.push('/orderManagement');
         }
         else{
             localStorage.removeItem('jwt');
         }})
     .catch((e) => (
         this.error = e,
         localStorage.removeItem('jwt')
     ))
    .finally(
    (msg) =>
    (this.message = { title: "finallyを実行しました", message: msg })
    );
  },
  methods: {
    handleLogin() {
      if (this.validateInputs()) {
        this.login();
      } else {
        alert(this.error.join('\n'));
      }
    },
    validateInputs() {
      var result = true;
      this.error = [];
      if (!this.loginId) {
        this.error.push(messages.E_SC001_02.replace('{0}', 'ログインID'));
        result = false;
      }
      if (!this.password) {
        this.error.push(messages.E_SC001_02.replace('{0}', 'パスワード'));
        result = false;
      }
      return result;
    },
    login() {
      axios.post(this.baseUrl + "/login/auth", new URLSearchParams({
          loginId: this.loginId,
          password: this.password,
      }, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})).then(response => {
        if (response.data.isSuccessLoginAuth) {
            // ログインに成功した場合の処理
            const jwt = response.data.jwt;
            // JWTをlocalStorageに保存する
            localStorage.setItem('jwt', jwt);
            // loginIdをlocalStorageに保存する(アカウント管理の使用)
            localStorage.setItem('loginId_key', this.loginId);
            if (response.status === 200) {
              // レスポンスが200の場合、注文履歴画面に遷移する
              this.$router.push('/orderManagement');
            }
        } else {
          alert(messages.E_SC001_03);
        }
      }).catch((e) => (
        this.error = e,
        alert(messages.E_SC001_04)
      ))
      .finally(
        (msg) =>
          (this.message = { title: "finallyを実行しました", message: msg })
      );
    }
  }
};
</script>